
import DropdownSelector from '@/components/DropdownSelector'

export default {
  components: {
    DropdownSelector,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTabIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showDropdown() {
      return this.tabs.length > 2
    },
    mobileTabs() {
      return this.tabs.map((tab, index) => ({ value: index, label: tab.name }))
    },
  },
  methods: {
    isActiveTab(index) {
      return index === this.activeTabIndex
    },
    isFirstTab(index) {
      return index === 0
    },
    isLastTab(index) {
      return index === this.tabs.length - 1
    },
    onTabClick(index) {
      this.$emit('tab-changed', index)
    },
  },
}
