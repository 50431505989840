
import { mapState } from 'vuex'

import HeroBanner from '@/components/HeroBanner'
import HeroTabs from '@/components/HeroTabs'
import ProductTile from '@/components/ProductTile'
import FeatureTile from '@/components/FeatureTile'

import ChevronIcon from '@/static/svg/chevron-icon.svg'
import { hrefLang } from '@/utils/constants'
export default {
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          url: `https://${this.hostUrl}`,
          potentialAction: {
            '@type': 'SearchAction',
            target: `https://${this.hostUrl}/search/{search_term_string}`,
            query: 'required',
            'query-input': 'required name=search_term_string',
          },
        },
        {
          '@type': 'Organization',
          name: 'Lenstore',
          url: `https://${this.hostUrl}`,
          logo: `https://${this.hostUrl}/png/lenstore-logo.png`,
          image: `https://${this.hostUrl}/png/lenstore-logo.png`,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.address?.address,
            postalCode: this.address?.postcode,
            addressCountry: this.address?.country,
          },
          openingHours: this.openingHours,
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: this.phoneNumber,
            contactType: 'ContactPoint',
          },
        },
      ],
    }
  },
  components: {
    HeroBanner,
    HeroTabs,
    ProductTile,
    ChevronIcon,
    FeatureTile,
  },
  middleware: 'actions',
  asyncData({ req }) {
    return { hostUrl: req?.headers?.host || window.location.host }
  },
  async fetch({ store }) {
    await store.dispatch('homepage/getHomepage')
  },
  head() {
    const langMeta = this.meta?.lang?.map((lang) => ({ rel: 'alternate', hreflang: lang.code, href: lang.url })) || []

    return {
      title: this.homepageMeta?.title || this.meta?.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.homepageMeta?.description || this.meta?.description,
        },
      ],
      link: [...langMeta, ...hrefLang],
    }
  },
  computed: {
    ...mapState(['address', 'errors', 'meta', 'openingHours', 'phoneNumber']),
    ...mapState('homepage', ['categories', 'activeCategory', 'tiles', 'homepageMeta']),
  },
  mounted() {
    this.updateDoubleOptIn()
  },
  methods: {
    changeTab(index) {
      this.$store.dispatch('homepage/setActiveCategory', index)
    },
    getContent(locationId) {
      return this.tiles.find((tile) => tile.location === locationId)?.content || []
    },
    async updateDoubleOptIn() {
      const doubleoptin = this.$route.query._a
      const doubleoptinToken = this.$route.query.token

      if (doubleoptin && doubleoptinToken) {
        try {
          await this.$store.dispatch('account/updateDoubleOptIn', { doubleoptinToken })
          const path = '/account/communication-preferences'
          if (!this.errors?.updateDoubleOptIn && this.$auth.loggedIn) {
            this.$router.push({
              path: this.$getLink('/communication-preferences'),
              name: `${this.$i18n.locale}/communication-preferences`,
              params: { doubleoptinConsent: 'true' },
            })
          } else if (!this.errors?.updateDoubleOptIn) {
            this.$store.commit('SET_LOGIN_REDIRECT_TO', path)
            this.$router.push({
              path: this.$getLink('login'),
              name: `${this.$i18n.locale}/login`,
              params: { doubleoptinConsent: 'true' },
            })
          }
        } catch {}
      }
    },
  },
}
