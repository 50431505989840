import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d89805e8&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=d89805e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89805e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroBanner: require('/var/app/staging/components/HeroBanner.vue').default,HeroTabs: require('/var/app/staging/components/HeroTabs.vue').default,ProductTile: require('/var/app/staging/components/ProductTile.vue').default,FeatureTile: require('/var/app/staging/components/FeatureTile.vue').default})
