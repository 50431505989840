export default {
  future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
  },
  content: [
    './components/**/*.{js,vue,ts}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}',
  ],
  // purge: {
  //   enabled: process.env.NODE_ENV === 'production',
  // },
  theme: {
    screens: {
      'mobile-m': '375px',
      tablet: '768px',
      'desktop-md': '1024px',
      desktop: '1440px',
      'desktop-xl': '1920px',
    },
    backgroundSize: {
      checkbox: '116%, 116%, cover',
    },
    gradientColorStops: (theme) => ({
      ...theme('colors'),
      'button-hero-top': '#dffcca',
      'button-hero-bottom': '#c4f8b8',
      'button-primary-top': '#8663F1',
      'button-primary-bottom': '#654ABB',
      'button-secondary-top': '#F0FDF8',
      'button-secondary-bottom': '#DFEFE9',
      'button-secondary-mid': '#E7F6F0',
      'button-error-top': '#ff6969',
      'button-error-bottom': '#da5639',
    }),
    extend: {
      backgroundImage: (theme) => ({
        'mother-daughter-baking-desktop': "url('/png/acuvue/mother-daughter-baking-desktop.png')",
        'mother-daughter-baking-mobile': "url('/png/acuvue/mother-daughter-baking-mobile.png')",
        'business-image-desktop': "url('/jpg/acuvue/business-image-desktop.jpg')",
        'business-image-mobile': "url('/jpg/acuvue/business-image-mobile.jpg')",
        'lifestyle-image-desktop': "url('/jpg/acuvue/lifestyle-image-desktop.jpg')",
        'lifestyle-image-mobile': "url('/jpg/acuvue/lifestyle-image-mobile.jpg')",
        'yoga-header-desktop': "url('/png/acuvue/yoga-header-desktop.png')",
        'yoga-header-mobile': "url('/png/acuvue/yoga-header-mobile.png')",
        'woman-on-laptop-smiling-desktop': "url('/png/acuvue/woman-on-laptop-smiling-desktop.png')",
        'woman-on-laptop-smiling-mobile': "url('/png/acuvue/woman-on-laptop-smiling-mobile.png')",
        'lifestyle-image-reusable-desktop': "url('/jpg/acuvue/lifestyle-image-reusable-desktop.jpg')",
        'lifestyle-image-reusable-mobile': "url('/jpg/acuvue/lifestyle-image-reusable-mobile.jpg')",
        'different-lens-desktop': "url('/jpg/acuvue/different-lens-desktop.jpg')",
        'different-lens-mobile': "url('/jpg/acuvue/different-lens-mobile.jpg')",
        tick: 'url(/svg/checkbox-tick.svg)',
      }),
      colors: {
        // Named colours
        'pale-purple': '#aea4b9',
        'slate-grey': '#514f5e',
        'light-purple': '#e8e2fb',
        'light-grey': '#ccccd1',
        'mid-grey': '#aea4b9',
        'mid-turquoise': '#aeeae7',
        'mid-purple': '#938fe7',
        'light-green': '#dfefe9',
        'dark-purple': '#5c40b3',
        'mid-green': '#b6eaa6',
        'light-blue': '#c1dae7',
        'light-yellow': '#fff7b6',
        'dark-yellow': '#fec43a',
        'order-confirm': '#defff3',
        'acuvue-light': '#cfe8f8',
        magnolia: '#f7f5ff',
        'light-teal': '#e6fffa',

        // Needs to be refactored once colours are mapped from UX
        default: '#514f5e', // slate grey
        dark: '#514f5e', // dark
        'default-faint': '#9388a0', //
        navigation: '#14B7C2', // topaz
        primary: '#5C40B3', // blueberry
        'primary-dark': '#C1DAE7', // powder blue
        highlight: '#E8E2FB', // pale lilac
        'highlight-light': '#EDFFF9', // ice
        'error-red': '#FF6969', // salmon
        discount: '#FFA485',
        'discount-secondary': '#CBFFA8',
        'border-primary': '#cce8e9',
        'border-secondary': '#c1dae7',
        'background-primary': '#e3f0f5',
        'background-active': '#443ea3',
        'background-secondary': '#f6f5f5',
        'error-primary': '#f66e6e',
        'error-secondary': '#fff0f0',
        'success-primary': '#00c982',
        'success-secondary': '#edfff9',
        'acuvue-brand-link': '#05a5e1',
      },
      fontSize: {
        0: '0',
        '3xs': '0.5rem',
        '2xs': '0.6875rem',
        '1xl': '1.25rem',
        'card-txt': '0.688rem',
        'accordion-header': '1.1rem',
        '5/5xl': '3.75rem',
        'reviews-header': '1rem',
        ms: '0.875rem',
      },
      fontFamily: {
        sans: ['DM Sans', 'Verdana', 'Arial', 'sans-serif'],
      },
      height: {
        7: '1.75rem',
        11: '3.125rem',
        13: '3.25rem',
        22.5: '5.625rem',
        39: '9.75rem',
        45: '11.25rem',
        50: '12.5rem',
        56: '14rem',
        58: '14.5rem',
        60: '15rem',
        65: '16rem',
        78: '19.37rem',
        80: '20rem',
        86: '21.5rem',
        90: '22.5rem',
        96: '24rem',
        130: '32.5rem',
        147.5: '36.875rem',
        153: '38.25rem',
        'img-xs': '10rem', // 160x160
        'img-s': '12.125rem', // 194x194
        'img-m': '17.5rem', // 280x280
        'img-m-product-tile': '16.25rem', // 280x250
        'img-lg': '25rem', // 400x400
        'img-lg-product': '21.25rem', // 400x340
        'img-xl': '20rem', // 320x320
        'img-xxl': '31.25rem', // 500x500
        'img-xxl-product': '27.5rem', // 500x440
        'feature-img-lg': '18.125rem',
        'mobile-header': '3.25rem',
        'empty-basket': '12.813rem',
        'img-sm-basket': '12.25rem',
        'img-m-basket': '19.375rem',
        'img-xl-basket': '20.625rem',
        'img-basket-item-90': '5.625rem',
        'img-basket-item-120': '7.5rem',
        'img-basket-item-180': '11.25rem',
        'desktop-header': '97px',
        'desktop-search': '3.625rem',
        'menu-header': '3.1rem',
        'menu-subheader': '2.537rem',
      },
      inset: {
        '-1px': '-1px',
        '23px': '23px',
        '-4': '-1rem',
        '-6': '-1.5rem',
        '-8': '-2rem',
        1: '0.25rem',
        1.5: '0.375rem',
        2: '0.5rem',
        2.5: '0.625rem',
        4: '1rem',
        5: '1.25rem',
        9: '2.25rem',
        10: '2.625rem',
        account: '2.41rem',
        6: '1.5rem',
        8: '2rem',
        12: '3rem',
        16: '4rem',
        13: '3.25rem',
        14: '3.375rem',
        32: '8rem',
      },
      margin: {
        2: '0.5rem',
        1.5: '0.375rem',
        '-1.5': '-0.375rem',
        '-44': '-11rem',
        '-37': '-9.25rem',
        '-28': '-7rem',
        '-23': '-5.75rem',
        '-9': '-2.25rem',
        '-15px': '-0.9375rem',
        '-2.5': '-0.625rem',
        '-30px': '-1.875rem',
        2.5: '0.625rem',
        5: '1.25rem',
        6.5: '1.625rem',
        7: '1.75rem',
        7.5: '1.875rem',
        9: '2.25rem',
        10: '2.5rem',
        11: '2.75rem',
        13: '3.188rem',
        14: '3.5rem',
        15: '3.75rem',
        18: '4.375rem',
        19: '4.75rem',
        25: '6.25rem',
        28: '7rem',
        60: '15rem',
        80: '20rem',
        116: '29rem',
        120: '30rem',
        320: '80rem',
        70: '4.375rem',
        35.5: '8.875rem',
        51.5: '12.875rem',
        'm-container': '3.125rem',
        'burger-header': '3.563rem',
        'hero-banner': '-0.4rem',
      },
      maxHeight: {
        45: '15.5rem',
        75: '18.75rem',
        80: '20rem',
        96: '24rem',
        100: '25rem',
        136: '34rem',
        '75vh': '75vh',
      },
      maxWidth: {
        90: '22.5rem',
        100: '29.25rem',
        125: '31.25rem',
        150: '37.5rem',
        '2.5xl': '44rem',
        'main-container': '120rem',
        '7xl': '80rem',
        '2/5': '40%',
        '1/2': '50%',
        '3/5': '60%',
        '4/5': '80%',
        'feature-tile': '54rem',
        'menu-column-md': '12.5rem',
        'menu-column': '18rem',
        'checkout-layout': '736px',
      },
      minWidth: {
        'feature-text': '12.5rem',
      },
      minHeight: {
        29: '7.25rem',
        45: '11.25rem',
      },
      padding: {
        '3px': '3px',
        '9px': '9px',
        '18px': '1.125rem',
        0.5: '0.125rem',
        2: '0.5rem',
        2.5: '0.625rem',
        3: '0.75rem',
        3.5: '0.875rem',
        6.5: '1.625rem',
        7: '1.75rem',
        9: '2.25rem',
        11: '2.75rem',
        12.5: '3.35rem',
        19: '4.75rem',
        18: '4.375rem',
        25: '6.25rem',
        26: '6.625rem',
        28: '7rem',
        33: '8.5rem',
        35: '8.75rem',
        41: '10.25rem',
        44: '11rem',
        45: '11.25rem',
        51: '13.063rem',
        'feature-heading': '2.75rem',
        'm-container': '3.125rem',
        'menu-xl': '5.8rem',
        'menu-item': '0.875rem',
      },
      width: {
        8: '2rem',
        18: '4.5rem',
        25: '6.25rem',
        28: '7rem',
        39: '9.75rem',
        41: '10.25rem',
        42.5: '10.625rem',
        44: '11rem',
        45: '11.25rem',
        50: '12.5rem',
        52: '13rem',
        55: '13.75rem',
        59: '14.75rem',
        60: '15rem',
        72: '18rem',
        75: '18.75rem',
        80: '20rem',
        105: '26.25rem',
        'img-xs': '10rem', // 160x160
        'img-s': '12.125rem', // 194x194
        'img-m': '17.5rem', // 280x280
        'img-lg': '25rem', // 400x400
        'img-xl': '20rem', // 320x320
        'img-xxl': '31.25rem', // 500x500
        'quantity-drop': '7.25rem',
        'feature-img-lg': '18.125rem',
        'feature-img': '22.5rem',
        '3/10': '30%',
        '7/10': '70%',
        'login-form': '37.5rem',
        'input-field': '31.25rem',
        'sm-input': '25rem',
        'img-sm-basket': '12.25rem',
        'img-m-basket': '16rem',
        'img-xl-basket': '20.625rem',
        'img-basket-item-90': '5.625rem',
        'img-basket-item-120': '7.5rem',
        'img-basket-item-180': '11.25rem',
        '4/5': '80%',
        '9/10': '90%',
        'main-container': '120rem',
        'payment-btn': '19.5rem',
        'desktop-btn': '31.25rem', // 500px
        'payment-input': '10.625rem',
        'sm-payment-input': '7.188rem',
        'cvv-text': '6rem',
        'm-container': '3.125rem',
        'menu-xl': '5.8rem',
        'trust-pilot': '420px',
        'trust-pilot-es': '440px',
        'trust-pilot-it': '460px',
        'xs-e-trusted': '420px',
        'e-trusted': '520px',
      },
      keyframes: {
        progress: {
          '0%': {
            transform: 'scaleX(0)',
          },
          '100%': {
            transform: 'scaleX(1)',
          },
        },
        fadeIn: {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
        fadeOut: {
          '0%': {
            opacity: 1,
            visibility: 'visible',
          },
          '100%': {
            opacity: 0,
            visibility: 'hidden',
          },
        },
        show: {
          '0%': {
            opacity: 0,
            visibility: 'visible',
          },
          '20%': {
            opacity: 1,
          },
          '80%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0,
            visibility: 'hidden',
          },
        },
        rightCloud: {
          '0%': {
            overflow: 'visible',
            'z-index': '99',
            transform: 'translateX(-10px)',
          },
          '50%': {
            transform: 'translateX(10px)',
          },
          '100%': {
            transform: 'translateX(-10px)',
          },
        },
        leftCloud: {
          '0%': {
            overflow: 'visible',
            'z-index': '99',
            transform: 'translateX(10px)',
          },
          '50%': {
            transform: 'translateX(-10px)',
          },
          '100%': {
            transform: 'translateX(10px)',
          },
        },
        bounce: {
          '0%': {
            transform: 'translateY(0)',
          },
          '25%': {
            transform: 'translateY(-3px)',
          },
          '50%': {
            transform: 'translateY(0)',
          },
          '75%': {
            transform: 'translateY(-1px)',
          },
          '100%': {
            transform: 'translateY(0)',
          },
        },
        rotation: {
          '0%': {
            transform: 'rotate(0)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      },
      animation: {
        progress: 'progress 1500ms ease-in-out infinite',
        'fade-in': 'fadeIn 250ms ease-in 1 100ms',
        'fade-out': 'fadeOut 250ms 3s forwards ease-out',
        show: 'show 700ms 0s forwards ease-in-out',
        bounce: 'bounce 650ms linear 350ms',
        'infinite-rotation': 'rotation 4s infinite linear',
      },
      boxShadow: {
        'pop-up': '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        'inner-primary': 'inset 0 0 0 1px #5C40B3',
      },
      opacity: {
        70: '0.7',
        90: '0.9',
      },
      backgroundOpacity: {
        30: '0.3',
      },
      lineHeight: {
        8.2: '1.82rem',
        'card-heading': '0.1rem',
      },
      borderRadius: {
        1: '0.063rem',
      },
      zIndex: {
        1: 1,
      },
    },
  },
  variants: {
    scrollbar: ['rounded'],
  },
  plugins: [],
}
