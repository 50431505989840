import { render, staticRenderFns } from "./HeroTabs.vue?vue&type=template&id=4d1b63dc"
import script from "./HeroTabs.vue?vue&type=script&lang=js"
export * from "./HeroTabs.vue?vue&type=script&lang=js"
import style0 from "./HeroTabs.vue?vue&type=style&index=0&id=4d1b63dc&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DropdownSelector: require('/var/app/staging/components/DropdownSelector.vue').default})
