
import { mapState } from 'vuex'

import tailwindConfig from '@/tailwind.config.js'

import HeroButton from '@/components/HeroButton'
import { cdnPath, cdnPathWebP } from '@/utils/constants'

export default {
  components: {
    HeroButton,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
      carouselOptions: {
        slidesToShow: 1,
        lazyLoad: 'ondemand',
        fade: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: true,
        arrows: false,
        dots: true,
        dotsClass: 'banner-carousel__dots',
        draggable: this.content.length > 1,
        swipe: true,
      },
      tablet: parseInt(tailwindConfig.theme.screens.tablet, 10),
      desktopMd: parseInt(tailwindConfig.theme.screens['desktop-md'], 10),
    }
  },
  computed: {
    ...mapState(['cdn']),
  },
  methods: {
    cdnPathWebP,
    cdnPath,
    navigate(contentItem) {
      this.$router.push(contentItem.ctaLink)
    },
    preventDrag(event) {
      event.preventDefault()
    },
    beforeBannerChange(_oldSlideIndex, newSlideIndex) {
      this.currentSlideIndex = newSlideIndex
    },
  },
}
