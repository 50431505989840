
import FullStarIcon from '@/static/svg/reviews/full-star-icon.svg'
import EmptyStarIcon from '@/static/svg/reviews/empty-star-icon.svg'

export default {
  components: {
    FullStarIcon,
    EmptyStarIcon,
  },
  props: {
    stars: {
      type: Number,
      default: 0,
    },
  },
}
