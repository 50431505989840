import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=adc1ac18"
import script from "./ProductTile.vue?vue&type=script&lang=js"
export * from "./ProductTile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductImage: require('/var/app/staging/components/ProductImage.vue').default,ReviewStars: require('/var/app/staging/components/ReviewStars.vue').default})
