
import { mapState } from 'vuex'
import { cdnPath, cdnPathWebP } from '@/utils/constants'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['cdn']),
  },
  methods: { cdnPathWebP, cdnPath },
}
