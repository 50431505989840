
import { mapState } from 'vuex'
import HeroButton from '@/components/HeroButton'
import LenstoreIcon from '@/static/svg/social/lenstore-hub-icon.svg'
import tailwindConfig from '@/tailwind.config.js'
import { cdnPath, cdnPathWebP } from '@/utils/constants'

export default {
  components: {
    HeroButton,
    LenstoreIcon,
  },
  props: {
    trackId: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    buttonType: {
      type: String,
      default: 'hero',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tablet: parseInt(tailwindConfig.theme.screens.tablet, 10),
      desktopMd: parseInt(tailwindConfig.theme.screens['desktop-md'], 10),
    }
  },
  computed: {
    ...mapState(['cdn']),
  },
  methods: {
    cdnPath,
    cdnPathWebP,
    navigate(contentItem) {
      this.$router.push(contentItem.ctaLink)
    },
  },
}
